@layer base {
  .main {
  margin-top: var(--sk-space-24);
}

.addNewCv {
  width: 100%;
  margin-top: var(--sk-space-8);
}

.header {
  margin-bottom: var(--sk-space-16);
}

@media (--sk-large-viewport) {
  .main {
    margin-top: var(--sk-space-32);
  }
}

}