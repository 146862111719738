@layer modules, ui, base;
@layer base {
  .Cv_main__ThqYm {
  margin-top: var(--sk-space-24);
}

.Cv_addNewCv__srtZn {
  width: 100%;
  margin-top: var(--sk-space-8);
}

.Cv_header__Bl9q3 {
  margin-bottom: var(--sk-space-16);
}

@media (min-width: 1312px) {
  .Cv_main__ThqYm {
    margin-top: var(--sk-space-32);
  }
}

}
@layer ui {
  .sk-InputWrapper_main__1zVBN {
  display: flex;
  flex-direction: column;
  font-family: var(--sk-font-families-poppins);
}

.sk-InputWrapper_labelWrapper__RFcXM {
  display: flex;
  gap: var(--sk-space-4);
  align-items: center;
  margin-bottom: var(--sk-space-8);
}

.sk-InputWrapper_labelWrapper--large__dv8Ff {
  margin-bottom: var(--sk-space-12);
}

.sk-InputWrapper_subLabel___4Fiu {
  font-family: var(--sk-typography-caption-1-font-family);
  font-size: var(--sk-typography-caption-1-font-size);
  font-weight: var(--sk-typography-caption-1-font-weight);
  line-height: var(--sk-typography-caption-1-line-height);
}

.sk-InputWrapper_messageWrapper__BsKEb {
  display: flex;
  justify-content: space-between;
  margin-top: var(--sk-space-8);
  gap: var(--sk-space-8);
}

.sk-InputWrapper_message__4RFam,
.sk-InputWrapper_secondaryMessage__UwXuM {
  font-family: var(--sk-typography-caption-1-font-family);
  font-size: var(--sk-typography-caption-1-font-size);
  font-weight: var(--sk-typography-caption-1-font-weight);
  line-height: var(--sk-typography-caption-1-line-height);
}

.sk-InputWrapper_secondaryMessage__UwXuM {
  margin-left: auto;
}

}
